import { Controller } from '@hotwired/stimulus';
import { Collapse } from 'bootstrap';

export default class extends Controller {
  static targets = ['panel', 'submit', 'status'];

  static values = {
    instanceId: String,
    messageFrame: String
  };

  connect() {
    this.boundHandleTurboStreamRender = this.handleTurboStreamRender.bind(this);

    document.addEventListener(
      'turbo:before-stream-render',
      this.boundHandleTurboStreamRender
    );
  }

  disconnect() {
    document.removeEventListener(
      'turbo:before-stream-render',
      this.boundHandleTurboStreamRender
    );
  }

  hide(e) {
    e.preventDefault();
    const collapsable = new Collapse(this.panelTarget);
    collapsable.hide();
    this.submitTarget.disabled = false;
    setTimeout(() => {
      this.panelTarget.remove();
    }, 1000);
  }

  messageFrameValueChanged() {
    if (this.hasMessageFrameValue) {
      this.submitTarget.disabled = true;
    } else {
      this.submitTarget.disabled = false;
    }
  }

  handleTurboStreamRender(event) {
    if (event.target.target === this.instanceIdValue) {
      this.assignActiveMessageFrame(event);
    } else if (this.eventMatches(event)) {
      const defaultRender = event.detail.render;
      // eslint-disable-next-line no-param-reassign
      event.detail.render = (streamEvent) => this.processTurboStream(streamEvent, defaultRender);
    }
  }

  eventMatches(event) {
    return this.hasMessageFrameValue
      && event.target.target === this.messageFrameValue;
  }

  assignActiveMessageFrame(event) {
    const messageFrame = event.target.templateContent.querySelector('turbo-frame').id;
    this.messageFrameValue = messageFrame;
  }

  processTurboStream(streamEvent, defaultRender) {
    if (streamEvent.action === 'chat-status') {
      const statusMessage = streamEvent.templateElement.content.firstElementChild.outerHTML;
      this.messageFrameValue = undefined;
      this.statusTarget.innerHTML = statusMessage;
    } else {
      defaultRender(streamEvent);
    }
  }
}
