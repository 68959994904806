import { Controller } from '@hotwired/stimulus';
import { Marked } from 'marked';

const MARKDOWN_CONFIG = {
  breaks: true,
  gfm: true,
  headerIds: false,
  mangle: false
};

// Connects to data-controller="markdown"
export default class extends Controller {
  static targets = ['raw', 'rendered', 'streamingContent'];

  initialize() {
    this.marked = new Marked(MARKDOWN_CONFIG);
  }

  connect() {
    this.configureMutationObserver();
  }

  disconnect() {
    this.observer?.disconnect();
  }

  configureMutationObserver() {
    this.observer = new MutationObserver(this.renderMarkdown.bind(this));
    this.observer.observe(this.streamingContentTarget, {
      characterData: true,
      childList: true,
      subtree: true
    });
  }

  renderMarkdown() {
    const markdownContent = this.marked.parse(this.rawTarget.innerHTML);
    this.renderedTarget.innerHTML = markdownContent;
  }
}
